// import * as axios from "../http"
const axios = require("../http");
let test = "https://demo.haijigis.com:18095/api/zyy/scene"
module.exports = {
    name: "能耗接口",
    getAllDevice: (params) => {
        return axios.get("http://10.8.18.253:81/api/DeveviceManagement/Device-synchronization-Get", params);
        // return axios.get(test, params)
    },
}
