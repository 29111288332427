<template>
  <div class="left">
    <div class="area">
      <div class="area_title">
        总用电量
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="display: flex;justify-content: center;align-items: center">
        <div class="electricity_img"></div>
        <div class="electricity_text">
          <div class="electricity_label">累计电量</div>
          <div class="electricity_value">1200 KW·h</div>
        </div>
      </div>
    </div>
    <div class="area">
      <div class="area_title">
        日用电量趋势图
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;">
        <div id="L_echarts1" style="width: 100%;height: 180px;"></div>
      </div>
    </div>
    <div class="area">
      <div class="area_title">
        月用电量趋势图
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;">
        <div id="L_echarts2" style="width: 100%;height: 180px;"></div>
      </div>
    </div>
    <div class="area">
      <div class="area_title">
        年用电量趋势图
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;">
        <div id="L_echarts3" style="width: 100%;height: 150px;"></div>
      </div>
    </div>
    <ChoseBuilding
        :useWaterInfo="useWaterInfo"
        :useElectricInfo="useElectricInfo"
        @getUseWaterInfo="getUseWaterInfo"
        @getUseElectricInfo="getUseElectricInfo"/>
  </div>
  <div class="right">
    <div class="area">
      <div class="area_title">
        总用水量
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="display: flex;justify-content: center;align-items: center">
        <div class="water_img"></div>
        <div class="electricity_text">
          <div class="electricity_label">累计水量</div>
          <div class="electricity_value">1200 M³</div>
        </div>
      </div>
    </div>
    <div class="area">
      <div class="area_title">
        日用水量趋势图
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;">
        <div id="R_echarts1" style="width: 100%;height: 180px;"></div>
      </div>
    </div>
    <div class="area">
      <div class="area_title">
        月用水量趋势图
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;">
        <div id="R_echarts2" style="width: 100%;height: 180px;"></div>
      </div>
    </div>
    <div class="area">
      <div class="area_title">
        年用水量趋势图
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding-top: 0;">
        <div id="R_echarts3" style="width: 100%;height: 150px;"></div>
      </div>
    </div>
  </div>
  <div class="pop_use_water" id="pop_use_water" v-show="useWaterInfo.isUseWater">
    <div class="water_list">
      <div class="close-icon" @click="useWaterInfo.isUseWater=false"></div>
      <div class="header">
        <p class="text">{{ useWaterInfo.equipmentName }}</p>
      </div>
      <div class="content">
        <div class="content_">
          <p class="text_">楼层：</p>
          <p class="text_1">5号楼1层SD-BT3</p>
        </div>
        <div class="content_">
          <p class="text_">状态：</p>
          <p class="text_2">在线</p>
        </div>
        <div class="content_">
          <button class="water_btn" @click="initWaterDay">日用水量</button>
          <button class="water_btn" @click="initWaterMouth">月用水量</button>
          <button class="water_btn" @click="initWaterYear">年用水量</button>
        </div>
      </div>
    </div>
    <div class="water_chart" v-show="isWaterEcharts">
      <div class="header">
        <p class="text">{{ useWaterType }}</p>
      </div>
      <div class="echarts_water" id="echarts_water" style="width: 248px;height: 160px;"></div>
    </div>
  </div>
  <div class="pop_use_electric" id="pop_use_electric" v-show="useElectricInfo.isUseElectric">
    <div class="electric_list">
      <div class="close-icon" @click="useElectricInfo.isUseElectric=false"></div>
      <div class="header">
        <p class="text">{{ useElectricInfo.equipmentName }}</p>
      </div>
      <div class="content">
        <div class="content_">
          <p class="text_">楼层：</p>
          <p class="text_1">5号楼1层SD-BT3</p>
        </div>
        <div class="content_">
          <p class="text_">状态：</p>
          <p class="text_2">在线</p>
        </div>
        <div class="content_">
          <button class="electric_btn" @click="initElectricDay">日用电量</button>
          <button class="electric_btn" @click="initElectricMouth">月用电量</button>
          <button class="electric_btn" @click="initElectricYear">年用电量</button>
        </div>
      </div>
    </div>
    <div class="electric_chart" v-show="isElectricEcharts">
      <div class="header">
        <p class="text">{{ useElectricType }}</p>
      </div>
      <div class="echarts_electric" id="echarts_electric" style="width: 248px;height: 160px;"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import ChoseBuilding from "./ChoseBuilding";
import Global from "@/js/global";
import Message from "../js/Message";
import * as axios from "../utils/http"
import * as energyApi from "../utils/request/energy"

export default {
  name: "EnergyConsumption",
  components: {ChoseBuilding},
  data() {
    return {
      useWaterType: "",
      useElectricType: "",
      isWaterEcharts: false,
      isElectricEcharts: false,
      focus: false,
      name: "",
      devName: "",
      devList: [],
      allDevList: [],
      color: ["#D27D69", "#F8D8B0", "#30D6C2", "#BF79AF"],
      choseIndex: 1,
      useWaterInfo: {
        classroomNumber: "",
        equipmentName: "",
        isUseWater: false,
        chosePoint: "",
      },
      useElectricInfo: {
        classroomNumber: "",
        equipmentName: "",
        isUseElectric: false,
        chosePoint: "",
      },
    };
  },
  mounted() {
    this.getPoints();
    this.initL1();
    this.initL2();
    this.initL3();
    this.initR1();
    this.initR2();
    this.initR3();

    this.getApiData()
  },
  unmounted() {
    this.useWaterInfo.isUseWater = false
    this.useElectricInfo.isUseElectric = false
    clearInterval(this.timer)
    if (window.myChart_1) {
      window.myChart_1.dispose();
      window.myChart_1 = null;
      window.myChart_2.dispose();
      window.myChart_2 = null;
      window.myChart_3.dispose();
      window.myChart_3 = null;
      window.myChart_4.dispose();
      window.myChart_4 = null;
      window.myChart_5.dispose();
      window.myChart_5 = null;
      window.myChart_6.dispose();
      window.myChart_6 = null;
    }
    if (window.myChart_water) {
      window.myChart_water.dispose();
      window.myChart_water = null;
    }
    if (window.myChart_electric) {
      window.myChart_electric.dispose();
      window.myChart_electric = null;
    }
  },
  watch: {
    "useWaterInfo.isUseWater": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.useElectricInfo.isUseElectric = false
          Message.setPopPosition("pop_use_water", this.useWaterInfo.chosePoint)
          let vector2 = Global.vector3Tovector2(this.useWaterInfo.chosePoint)
          new Global.DragAndZoom("pop_use_water", vector2)
        } else {
          scene.onBeforeRenderObservable.remove(Message.observer)
        }
      }
    },
    "useElectricInfo.isUseElectric": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.useWaterInfo.isUseWater = false
          Message.setPopPosition("pop_use_electric", this.useElectricInfo.chosePoint)
          let vector2 = Global.vector3Tovector2(this.useElectricInfo.chosePoint)
          new Global.DragAndZoom("pop_use_electric", vector2)
        } else {
          scene.onBeforeRenderObservable.remove(Message.observer)
        }
      }
    }

  },
  methods: {
    getApiData() {
      let params = {keyword: "水表"}
      // let params = {scene_id: "8c51f3c0-542e-41a6-b722-f9162b92c5a4"}
      energyApi.getAllDevice(params).then(res => {
        console.log("能耗接口数据", res)
      })
    },
    getUseWaterInfo(info) {
      this.useWaterInfo = info
    },
    getUseElectricInfo(info) {
      this.useElectricInfo = info
    },
    getPoints() {
      let dev = Global.findEquipment("light");
      for (let item of dev) {
        item.date = "2022-10-08";
      }
      this.devList = dev;
      this.allDevList = [...dev];
    },
    findDev() {
      let arr = [];
      if (this.devName) {
        for (let dev of this.allDevList) {
          let i = dev.name.indexOf(this.devName);
          if (i > -1) {
            arr.push(dev)
          }
        }
      } else {
        arr = [...this.allDevList];
      }
      this.devList = arr;
    },
    checkDev(data) {
      let flag = data.show
      for (let item of this.devList) {
        item.show = false
      }
      data.show = !flag
    },
    choseLabel(data) {
      this.name = data.name;
      this.focus = false;
    },
    initL1() {
      let container = document.getElementById("L_echarts1");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#979293'
            }
          },
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        },
        yAxis: {
          type: 'value',

          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false,
          },
          splitLine: {

            lineStyle: {
              color: '#30D6C2'
            }
          }
        },
        series: [
          {
            name: '浏览量',
            type: 'line',
            stack: '总量',
            //symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: '#EB6E78',
                lineStyle: {
                  color: "#EB6E78",
                  width: 2
                },
                areaStyle: {
                  color: 'rgba(235,110,120,0.3)'

                }
              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190]
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_1 = myChart_;
    },
    initL2() {
      let container = document.getElementById("L_echarts2");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#979293'
            }
          },


          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value',

          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false,
          },
          splitLine: {

            lineStyle: {
              color: '#30D6C2'
            }
          }
        },
        series: [

          {
            name: '浏览量',
            type: 'line',
            stack: '总量',
            //symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: '#EB6E78',
                lineStyle: {
                  color: "#EB6E78",
                  width: 2
                },

              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190]
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_2 = myChart_;
    },
    initL3() {
      let container = document.getElementById("L_echarts3");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {

            lineStyle: {
              color: '#91544D'
            }
          },

        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff'
          },
          data: ['2021', '2022', '2023']
        },
        series: [
          {
            data: [120, 200, 150],
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#F09861',

              }
            },
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_3 = myChart_;
    },
    initR1() {
      let container = document.getElementById("R_echarts1");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#979293'
            }
          },
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        },
        yAxis: {
          type: 'value',

          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false,
          },
          splitLine: {

            lineStyle: {
              color: '#30D6C2'
            }
          }
        },
        series: [
          {
            name: '浏览量',
            type: 'line',
            stack: '总量',
            //symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: '#75F9FD',
                lineStyle: {
                  color: "#75F9FD",
                  width: 2
                },
                areaStyle: {
                  color: 'rgba(117,249,253,0.3)'

                }
              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190]
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_4 = myChart_;
    },
    initR2() {
      let container = document.getElementById("R_echarts2");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#979293'
            }
          },


          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value',

          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false,
          },
          splitLine: {

            lineStyle: {
              color: '#30D6C2'
            }
          }
        },
        series: [

          {
            name: '浏览量',
            type: 'line',
            stack: '总量',
            //symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: '#75F9FD',
                lineStyle: {
                  color: "#75F9FD",
                  width: 2
                },

              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190]
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_5 = myChart_;
    },
    initR3() {
      let container = document.getElementById("R_echarts3");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {

            lineStyle: {
              color: '#91544D'
            }
          },

        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff'
          },
          data: ['2021', '2022', '2023']
        },
        series: [
          {
            data: [120, 200, 150],
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#59C7C8',
              }
            },
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_6 = myChart_;
    },
    locateModel(data) {
      Global.choseEquipment(data);
    },
    initWaterDay() {
      this.useWaterType = "日用水量"
      this.isWaterEcharts = true
      let container = document.getElementById("echarts_water");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#979293'
            }
          },
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        },
        yAxis: {
          type: 'value',

          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false,
          },
          splitLine: {

            lineStyle: {
              color: '#30D6C2'
            }
          }
        },
        series: [
          {
            name: '浏览量',
            type: 'line',
            stack: '总量',
            //symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: '#75F9FD',
                lineStyle: {
                  color: "#75F9FD",
                  width: 2
                },
                areaStyle: {
                  color: 'rgba(117,249,253,0.3)'

                }
              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190]
          }
        ]
      };
      myChart_.setOption(option);

      window.myChart_water = myChart_;
    },
    initWaterMouth() {
      this.useWaterType = "月用水量"
      this.isWaterEcharts = true
      let container = document.getElementById("echarts_water");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#979293'
            }
          },


          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value',

          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false,
          },
          splitLine: {

            lineStyle: {
              color: '#30D6C2'
            }
          }
        },
        series: [

          {
            name: '浏览量',
            type: 'line',
            stack: '总量',
            //symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: '#75F9FD',
                lineStyle: {
                  color: "#75F9FD",
                  width: 2
                },

              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190]
          }
        ]
      };
      myChart_.setOption(option);

      window.myChart_water = myChart_;


    },
    initWaterYear() {
      this.useWaterType = "年用水量"
      this.isWaterEcharts = true
      let container = document.getElementById("echarts_water");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {

            lineStyle: {
              color: '#91544D'
            }
          },

        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff'
          },
          data: ['2021', '2022', '2023']
        },
        series: [
          {
            data: [120, 200, 150],
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#59C7C8',
              }
            },
          }
        ]
      };
      myChart_.setOption(option);

      window.myChart_water = myChart_;

    },
    initElectricDay() {
      this.useElectricType = "日用电量"
      this.isElectricEcharts = true
      let container = document.getElementById("echarts_electric");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#979293'
            }
          },
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        },
        yAxis: {
          type: 'value',

          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false,
          },
          splitLine: {

            lineStyle: {
              color: '#30D6C2'
            }
          }
        },
        series: [
          {
            name: '浏览量',
            type: 'line',
            stack: '总量',
            //symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: '#EB6E78',
                lineStyle: {
                  color: "#EB6E78",
                  width: 2
                },
                areaStyle: {
                  color: 'rgba(235,110,120,0.3)'

                }
              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190]
          }
        ]
      };
      myChart_.setOption(option);

      window.myChart_electric = myChart_;
    },
    initElectricMouth() {
      this.useElectricType = "月用电量"
      this.isElectricEcharts = true
      let container = document.getElementById("echarts_electric");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#979293'
            }
          },


          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value',

          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false,
          },
          splitLine: {

            lineStyle: {
              color: '#30D6C2'
            }
          }
        },
        series: [

          {
            name: '浏览量',
            type: 'line',
            stack: '总量',
            //symbol: 'circle',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: '#EB6E78',
                lineStyle: {
                  color: "#EB6E78",
                  width: 2
                },

              }
            },
            data: [150, 232, 201, 154, 190, 330, 410, 150, 232, 201, 154, 190]
          }
        ]
      };
      myChart_.setOption(option);

      window.myChart_electric = myChart_;


    },
    initElectricYear() {
      this.useElectricType = "年用电量"
      this.isElectricEcharts = true
      let container = document.getElementById("echarts_electric");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        grid: {
          left: '10px',
          right: '10px',
          top: '20px',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },

        xAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {

            lineStyle: {
              color: '#91544D'
            }
          },

        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff'
          },
          data: ['2021', '2022', '2023']
        },
        series: [
          {
            data: [120, 200, 150],
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#F09861',

              }
            },
          }
        ]
      };
      myChart_.setOption(option);

      window.myChart_water = myChart_;

      // console.log(myChart_)
      window.myChart_electric = myChart_;

    },
  },
};
</script>

<style lang="less" scoped>
.pop_use_water {
  width: calc(584px / 3 * 2 + 372px / 3 * 2 + 5);
  height: calc(323px / 3 * 2 + 285px / 3 * 2 + 5);
  position: fixed;
  left: 40%;
  top: 20%;
  display: flex;
  z-index: 10;
}

.water_list {
  position: relative;
  width: calc(584px / 3 * 2);
  height: calc(323px / 3 * 2);
  background-color: rgba(255, 255, 255, 0.0);
  -webkit-background-size: 100% 100%;
  background-image: url("../assets/image/bg/pop_water (2).png");
  background-size: 100% 100%;
}

.water_chart {
  width: calc(372px / 3 * 2);
  height: calc(285px / 3 * 2);
  background-image: url("../assets/image/bg/water_echart.png");
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;

  .header {
    width: 100%;
    height: 30px;

    .text {
      height: 16px;
      font-size: 16px;
      font-weight: 200;
      padding-left: 10px;
      padding-top: 10px;
      color: #FFFFFF;
    }
  }

  .echarts_water {
    //width: 100%;
    //height: calc(100% - 30px);
    //background-color: aqua;
  }
}

.pop_use_electric {
  width: calc(584px / 3 * 2 + 372px / 3 * 2 + 5);
  height: calc(323px / 3 * 2 + 285px / 3 * 2 + 5);
  position: fixed;
  left: 40%;
  top: 60%;
  display: flex;
  z-index: 10;
}

.electric_list {
  position: relative;
  width: calc(584px / 3 * 2);
  height: calc(323px / 3 * 2);
  background-color: rgba(255, 255, 255, 0.0);
  -webkit-background-size: 100% 100%;
  background-image: url("../assets/image/bg/pop_electronic.png");
  background-size: 100% 100%;

  .close-icon {
    background-image: url("../assets/image/bg/electric_close.png");
  }
}

.electric_chart {
  width: calc(372px / 3 * 2);
  height: calc(285px / 3 * 2);
  background-image: url("../assets/image/bg/electric_echart.png");
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;

  .header {
    width: 100%;
    height: 30px;

    .text {
      height: 16px;
      font-size: 16px;
      font-weight: 200;
      padding-left: 10px;
      padding-top: 10px;
      color: #FFFFFF;
    }
  }

  .echarts_electric {
    width: 100%;
    height: calc(100% - 30px);
    //background-color: aqua;
  }
}

.water_btn {
  width: calc(148px / 3 * 2);
  height: calc(53px / 3 * 2);
  background: rgba(22, 30, 27, 0.5);
  border: 2px solid #30D6C2;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 20px;

  font-family: 'FZZhengCu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /*line-height: 29px;*/
  color: #90F9FC;
}

.electric_btn {
  width: calc(148px / 3 * 2);
  height: calc(53px / 3 * 2);
  background: rgba(22, 30, 27, 0.5);
  border: 2px solid #F09861;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 20px;

  font-family: 'FZZhengCu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /*line-height: 29px;*/
  color: #F09861;
}

.electricity_label {
  margin-bottom: 10px;
}

.electricity_text div {
  font-size: 16px;
  color: #FFFFFF;
}

.electricity_img {
  width: calc(113px / 2);
  height: calc(125px / 2);
  background-image: url(../assets/image/icon/Group471.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  margin-right: 20px;
}

.water_img {
  width: calc(113px / 2);
  height: calc(125px / 2);
  background-image: url(../assets/image/icon/Group481.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  margin-right: 20px;
}

.info_col {
  font-size: 12px;
  color: #30D6C2;
}

.info {
  width: calc(100% - 20px);
  height: 60px;
  border: 1px solid #30D6C2;
  margin-left: 10px;
  background-color: rgba(14, 40, 37, 0.5);
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  justify-content: space-around;
}

.info_row {
  display: flex;
  justify-content: space-between;
}

.bg {
  background-image: linear-gradient(to right, rgba(88, 173, 169, 0.7) 0%, rgba(19, 21, 21, 0.1) 100%);
}

.search {
  position: absolute;
  right: 15px;
  top: -10px;
  width: 150px;
  height: 35px;
}

.search input {
  width: calc(100% - 30px);
  height: 100%;
  padding: 0 15px;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  background-color: transparent;
}

.warn_container {
  width: 100%;
  height: calc(100% - 100px);
  overflow: scroll;
}

.warn_item {
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  height: 27px;
  align-items: center;
  margin: 10px 0;
}

.warn_item .warn_text {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  position: relative;
}

.warn_title_text {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.warn_title {
  width: 100%;
  height: 37.5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #4CB0A4;
  /*background-image: radial-gradient(rgba(88, 173, 169, 0.6), rgba(19, 21, 21, 0.1));*/
}

.video_name {
  color: #fff;
}

.video {
  margin-bottom: 15PX;
}

.video_img {
  width: 170px;
  height: 96px;
  background-image: url(../assets/image/bg/video.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.video_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pie_num {
  width: 80px;
  height: 40px;
  color: #30D6C2;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 53px;
  top: 80px;
}

.pie_label {
  width: 100px;
  height: 25px;
  position: absolute;
  left: 43px;
  bottom: 3px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.label_line {
  width: 100%;
  bottom: 0;
  height: 1px;
  position: absolute;
  left: 0;
  background-image: linear-gradient(to right, rgba(144, 249, 252, 1) 0%, rgba(125, 125, 125, 0.4) 100%);
}

.label_text {
  margin-top: 4px;
  font-size: 14px;
  color: #ffffff;
}

.label_icon {
  width: 14px;
  height: 14px;
  margin-left: 6.5px;
  background-color: #fff;
}

.lebal {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 47px;
}

.label_container {
  position: absolute;
  width: 160px;
  right: 10px;
  top: 42px;
}

.pie_con {
  width: 100%;
  position: relative;
}

.chose_floor {
  background-image: url(../assets/image/bg/Group42.png) !important;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  color: #143F3A !important;
}

.floor_ {
  width: 96px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  color: #FFF;
  background-image: url(../assets/image/bg/Group33.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}

.floor {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

.floors {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  height: 160px;
  overflow: scroll;
}

.whirling {
  transform: rotateZ(-180deg);
}

.select_:first-child {
  margin-top: 10px;
}

.select_:last-child {
  margin-bottom: 10px;
}

.select_ {
  padding: 15px 30px;
  color: #1EBCBF;
  cursor: pointer;
}

.select_con {
  position: absolute;
  width: 130%;
  height: 200px;
  left: 0;
  top: 50px;
  z-index: 2;
  background-color: #fff;
  border-radius: 5px;
  overflow: scroll;
  transition: all 0.3s;
}

.input_arrow {
  width: 21px;
  transition: all 0.3s;
  height: 16.5px;
  background-image: url(../assets/image/icon/arrow.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  right: 10px;
  top: 15px;
}

.b_input ._input {
  width: 100%;
  height: 100%;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  padding: 0 15px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  line-height: 46px;
  font-size: 18px;
}

.b_input {
  position: relative;
  width: 150px;
  height: 46px;
  margin-left: 20px;
}

.icon1 {
  background-image: url(../assets/image/icon/xuesheng.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon2 {
  background-image: url(../assets/image/icon/laoshi.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon3 {
  background-image: url(../assets/image/icon/xuexiao.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon4 {
  background-image: url(../assets/image/icon/shiyan.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon5 {
  background-image: url(../assets/image/icon/fanjian.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.overview_left {
  margin-right: 14px;
}

.text {
  font-size: 24px;
  color: #30D6C2;
  margin-bottom: 5px;
}

.text_tip {
  font-size: 14px;
  color: #F2F2F2;
}

.overview_icon {
  width: 20px;
  height: 20px;
}

.circle {
  width: 19px;
  height: 19px;
  margin-bottom: 10px;
  background-color: rgba(48, 204, 214, 0.3);
  border-radius: 50%;
}

.area_content {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0px;
}

.area_content_ {
  padding: 20px 10px;
  padding-bottom: 0px;
}

.overview {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 138px;
}
</style>
